// @generated by protoc-gen-connect-es v0.8.6 with parameter "target=ts,import_extension=none"
// @generated from file api/proto/api/v1/psc.proto (package api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListEventsRequest, ListEventsResponse, ListFightersRequest, ListFightersResponse, ListResultsForEventRequest, ListResultsForEventResponse, ListResultsForFighterRequest, ListResultsForFighterResponse } from "./psc_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service api.v1.PSCService
 */
export const PSCService = {
  typeName: "api.v1.PSCService",
  methods: {
    /**
     * @generated from rpc api.v1.PSCService.ListEvents
     */
    listEvents: {
      name: "ListEvents",
      I: ListEventsRequest,
      O: ListEventsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.v1.PSCService.ListResultsForEvent
     */
    listResultsForEvent: {
      name: "ListResultsForEvent",
      I: ListResultsForEventRequest,
      O: ListResultsForEventResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.v1.PSCService.ListFighters
     */
    listFighters: {
      name: "ListFighters",
      I: ListFightersRequest,
      O: ListFightersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.v1.PSCService.ListResultsForFighter
     */
    listResultsForFighter: {
      name: "ListResultsForFighter",
      I: ListResultsForFighterRequest,
      O: ListResultsForFighterResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

