// @generated by protoc-gen-es v1.2.0 with parameter "target=ts"
// @generated from file api/proto/api/v1/psc.proto (package api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message api.v1.ListEventsRequest
 */
export class ListEventsRequest extends Message<ListEventsRequest> {
  constructor(data?: PartialMessage<ListEventsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.v1.ListEventsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEventsRequest {
    return new ListEventsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEventsRequest {
    return new ListEventsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEventsRequest {
    return new ListEventsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListEventsRequest | PlainMessage<ListEventsRequest> | undefined, b: ListEventsRequest | PlainMessage<ListEventsRequest> | undefined): boolean {
    return proto3.util.equals(ListEventsRequest, a, b);
  }
}

/**
 * @generated from message api.v1.ListEventsResponse
 */
export class ListEventsResponse extends Message<ListEventsResponse> {
  /**
   * @generated from field: repeated api.v1.Event events = 1;
   */
  events: Event[] = [];

  constructor(data?: PartialMessage<ListEventsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.v1.ListEventsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "events", kind: "message", T: Event, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEventsResponse {
    return new ListEventsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEventsResponse {
    return new ListEventsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEventsResponse {
    return new ListEventsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListEventsResponse | PlainMessage<ListEventsResponse> | undefined, b: ListEventsResponse | PlainMessage<ListEventsResponse> | undefined): boolean {
    return proto3.util.equals(ListEventsResponse, a, b);
  }
}

/**
 * @generated from message api.v1.ListResultsForEventRequest
 */
export class ListResultsForEventRequest extends Message<ListResultsForEventRequest> {
  /**
   * @generated from field: int32 event_id = 1;
   */
  eventId = 0;

  constructor(data?: PartialMessage<ListResultsForEventRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.v1.ListResultsForEventRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListResultsForEventRequest {
    return new ListResultsForEventRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListResultsForEventRequest {
    return new ListResultsForEventRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListResultsForEventRequest {
    return new ListResultsForEventRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListResultsForEventRequest | PlainMessage<ListResultsForEventRequest> | undefined, b: ListResultsForEventRequest | PlainMessage<ListResultsForEventRequest> | undefined): boolean {
    return proto3.util.equals(ListResultsForEventRequest, a, b);
  }
}

/**
 * @generated from message api.v1.ListResultsForEventResponse
 */
export class ListResultsForEventResponse extends Message<ListResultsForEventResponse> {
  /**
   * @generated from field: api.v1.Event event = 1;
   */
  event?: Event;

  /**
   * @generated from field: repeated api.v1.FightResult fight_results = 2;
   */
  fightResults: FightResult[] = [];

  constructor(data?: PartialMessage<ListResultsForEventResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.v1.ListResultsForEventResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event", kind: "message", T: Event },
    { no: 2, name: "fight_results", kind: "message", T: FightResult, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListResultsForEventResponse {
    return new ListResultsForEventResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListResultsForEventResponse {
    return new ListResultsForEventResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListResultsForEventResponse {
    return new ListResultsForEventResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListResultsForEventResponse | PlainMessage<ListResultsForEventResponse> | undefined, b: ListResultsForEventResponse | PlainMessage<ListResultsForEventResponse> | undefined): boolean {
    return proto3.util.equals(ListResultsForEventResponse, a, b);
  }
}

/**
 * @generated from message api.v1.ListFightersRequest
 */
export class ListFightersRequest extends Message<ListFightersRequest> {
  constructor(data?: PartialMessage<ListFightersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.v1.ListFightersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFightersRequest {
    return new ListFightersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFightersRequest {
    return new ListFightersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFightersRequest {
    return new ListFightersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListFightersRequest | PlainMessage<ListFightersRequest> | undefined, b: ListFightersRequest | PlainMessage<ListFightersRequest> | undefined): boolean {
    return proto3.util.equals(ListFightersRequest, a, b);
  }
}

/**
 * @generated from message api.v1.ListFightersResponse
 */
export class ListFightersResponse extends Message<ListFightersResponse> {
  /**
   * @generated from field: repeated api.v1.Fighter fighters = 1;
   */
  fighters: Fighter[] = [];

  constructor(data?: PartialMessage<ListFightersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.v1.ListFightersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fighters", kind: "message", T: Fighter, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFightersResponse {
    return new ListFightersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFightersResponse {
    return new ListFightersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFightersResponse {
    return new ListFightersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListFightersResponse | PlainMessage<ListFightersResponse> | undefined, b: ListFightersResponse | PlainMessage<ListFightersResponse> | undefined): boolean {
    return proto3.util.equals(ListFightersResponse, a, b);
  }
}

/**
 * @generated from message api.v1.ListResultsForFighterRequest
 */
export class ListResultsForFighterRequest extends Message<ListResultsForFighterRequest> {
  /**
   * @generated from field: int32 fighter_id = 1;
   */
  fighterId = 0;

  constructor(data?: PartialMessage<ListResultsForFighterRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.v1.ListResultsForFighterRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fighter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListResultsForFighterRequest {
    return new ListResultsForFighterRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListResultsForFighterRequest {
    return new ListResultsForFighterRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListResultsForFighterRequest {
    return new ListResultsForFighterRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListResultsForFighterRequest | PlainMessage<ListResultsForFighterRequest> | undefined, b: ListResultsForFighterRequest | PlainMessage<ListResultsForFighterRequest> | undefined): boolean {
    return proto3.util.equals(ListResultsForFighterRequest, a, b);
  }
}

/**
 * @generated from message api.v1.ListResultsForFighterResponse
 */
export class ListResultsForFighterResponse extends Message<ListResultsForFighterResponse> {
  /**
   * @generated from field: api.v1.Fighter fighter = 1;
   */
  fighter?: Fighter;

  /**
   * @generated from field: repeated api.v1.FightResult fight_results = 2;
   */
  fightResults: FightResult[] = [];

  constructor(data?: PartialMessage<ListResultsForFighterResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.v1.ListResultsForFighterResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fighter", kind: "message", T: Fighter },
    { no: 2, name: "fight_results", kind: "message", T: FightResult, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListResultsForFighterResponse {
    return new ListResultsForFighterResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListResultsForFighterResponse {
    return new ListResultsForFighterResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListResultsForFighterResponse {
    return new ListResultsForFighterResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListResultsForFighterResponse | PlainMessage<ListResultsForFighterResponse> | undefined, b: ListResultsForFighterResponse | PlainMessage<ListResultsForFighterResponse> | undefined): boolean {
    return proto3.util.equals(ListResultsForFighterResponse, a, b);
  }
}

/**
 * @generated from message api.v1.FightResult
 */
export class FightResult extends Message<FightResult> {
  /**
   * @generated from field: repeated api.v1.FighterResult fighter_results = 1;
   */
  fighterResults: FighterResult[] = [];

  /**
   * @generated from field: api.v1.Event event = 2;
   */
  event?: Event;

  /**
   * @generated from field: int32 result_ending_round = 3;
   */
  resultEndingRound = 0;

  /**
   * @generated from field: int32 result_ending_time_seconds = 4;
   */
  resultEndingTimeSeconds = 0;

  constructor(data?: PartialMessage<FightResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.v1.FightResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fighter_results", kind: "message", T: FighterResult, repeated: true },
    { no: 2, name: "event", kind: "message", T: Event },
    { no: 3, name: "result_ending_round", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "result_ending_time_seconds", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FightResult {
    return new FightResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FightResult {
    return new FightResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FightResult {
    return new FightResult().fromJsonString(jsonString, options);
  }

  static equals(a: FightResult | PlainMessage<FightResult> | undefined, b: FightResult | PlainMessage<FightResult> | undefined): boolean {
    return proto3.util.equals(FightResult, a, b);
  }
}

/**
 * @generated from message api.v1.FighterResult
 */
export class FighterResult extends Message<FighterResult> {
  /**
   * @generated from field: api.v1.Fighter fighter = 1;
   */
  fighter?: Fighter;

  /**
   * @generated from field: int32 significant_strikes = 2;
   */
  significantStrikes = 0;

  /**
   * @generated from field: int32 takedowns = 3;
   */
  takedowns = 0;

  /**
   * @generated from field: int32 knockdowns = 4;
   */
  knockdowns = 0;

  /**
   * @generated from field: int32 control_time_seconds = 5;
   */
  controlTimeSeconds = 0;

  /**
   * @generated from field: float score = 6;
   */
  score = 0;

  /**
   * True if the fighter won the fight
   *
   * @generated from field: bool win = 7;
   */
  win = false;

  /**
   * True if the fighter won the fight via stoppage
   *
   * @generated from field: bool win_by_stoppage = 8;
   */
  winByStoppage = false;

  /**
   * If the fighter won, this will be a string representing the method
   * of victory, e.g. "Decision - Unanimous" or "Submission" or "TKO/KO".bool
   * If the fighter did not win, will be an empty string
   *
   * @generated from field: string win_method = 9;
   */
  winMethod = "";

  /**
   * The corner the fighter was in, should be either 'red' or 'blue'
   *
   * @generated from field: string corner = 10;
   */
  corner = "";

  constructor(data?: PartialMessage<FighterResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.v1.FighterResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fighter", kind: "message", T: Fighter },
    { no: 2, name: "significant_strikes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "takedowns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "knockdowns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "control_time_seconds", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "win", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "win_by_stoppage", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "win_method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "corner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FighterResult {
    return new FighterResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FighterResult {
    return new FighterResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FighterResult {
    return new FighterResult().fromJsonString(jsonString, options);
  }

  static equals(a: FighterResult | PlainMessage<FighterResult> | undefined, b: FighterResult | PlainMessage<FighterResult> | undefined): boolean {
    return proto3.util.equals(FighterResult, a, b);
  }
}

/**
 * @generated from message api.v1.Fighter
 */
export class Fighter extends Message<Fighter> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string first_name = 2;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 3;
   */
  lastName = "";

  /**
   * @generated from field: string nick_name = 4;
   */
  nickName = "";

  constructor(data?: PartialMessage<Fighter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.v1.Fighter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "nick_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Fighter {
    return new Fighter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Fighter {
    return new Fighter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Fighter {
    return new Fighter().fromJsonString(jsonString, options);
  }

  static equals(a: Fighter | PlainMessage<Fighter> | undefined, b: Fighter | PlainMessage<Fighter> | undefined): boolean {
    return proto3.util.equals(Fighter, a, b);
  }
}

/**
 * @generated from message api.v1.Event
 */
export class Event extends Message<Event> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp date = 3;
   */
  date?: Timestamp;

  constructor(data?: PartialMessage<Event>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.v1.Event";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Event {
    return new Event().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Event {
    return new Event().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Event {
    return new Event().fromJsonString(jsonString, options);
  }

  static equals(a: Event | PlainMessage<Event> | undefined, b: Event | PlainMessage<Event> | undefined): boolean {
    return proto3.util.equals(Event, a, b);
  }
}

