import { Box, Center, Heading } from "@chakra-ui/react";

export const Home = () => {
  return (
    <Box>
      <Center>
        <Heading>Welcome to Poop Swag Championship</Heading>
      </Center>
    </Box>
  );
};
